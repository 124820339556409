import moment from 'moment'
import _ from 'lodash'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import httpClient from '@/utils/httpClient'
import VueToast from 'vue-toast-notification';
import VueCookies from 'vue-cookies'
import vuetify from '@/plugins/vuetify'
import * as Sentry from '@sentry/vue'
import VueI18n from 'vue-i18n';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import VCalendar from 'v-calendar'
import VueSweetalert2 from 'vue-sweetalert2'

// Global styles
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import 'sweetalert2/dist/sweetalert2.min.css'
import '@/styles/index.scss'
import appLangSwitcher from '@/components/lang-switcher/index.vue'
import formLangSwitcher from '@/components/form-lang-switcher/index.vue'

import '@fontsource/montserrat';

import './plugins/vue-shortkey'
import './plugins/vue-head'
import './plugins/apexcharts'
import './plugins/echarts'
import './plugins/clipboard'
import { localFeatures, timeouts } from '@/utils/constants';
import { customerFeatures } from '../localConfig';
import { BrowserTracing } from '@sentry/tracing';
import { createPinia, PiniaVuePlugin, setActivePinia } from 'pinia'
import { mergeTranslations } from '@/services/i18n';
import dispatcher from "@/api/dispatch";

for (let key of Object.keys(localFeatures)) {
  if (customerFeatures[key] !== undefined) {
    localFeatures[key] = customerFeatures[key]
  }
}

// Init Sentry.js
if (localFeatures.useSentry) {
  Sentry.init({
    Vue,
    dsn: 'https://41a38b5b4d244fb3a70d6520745a6dc6@o4504657761009664.ingest.sentry.io/4504657765007360',
    logErrors: true,
    release: 'bid4it-frontend@' + process.env.APP_VERSION,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV
  });
}

Vue.component('LangSiwtcher', appLangSwitcher)
Vue.component('FormLangSwitcher', formLangSwitcher)
Vue.component('VueCookieAcceptDecline', VueCookieAcceptDecline)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

Vue.use(VueToast, {
  position: 'bottom',
  duration: timeouts.closeToast
});

Vue.use(VueCookies)
Vue.$cookies.config('30s')

const sweetalertOptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
}

Vue.use(VueSweetalert2, sweetalertOptions)

// Init translations
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: mergeTranslations()
})

Vue.use(PiniaVuePlugin)

const pinia = createPinia()
setActivePinia(pinia)

Vue.prototype.moment = moment
Vue.prototype._ = _
// Vue.prototype.i18n = i18n
Vue.prototype.enableThemeSwitcher = localFeatures.enableThemeSwitcher

Vue.config.productionTip = false

// generic error handler
if (process.env.NODE_ENV === 'development' && localFeatures.showErrorsOnDevelopment) {
  Vue.config.errorHandler = async (err, vm, info) => {
    const stacktrace = err.stack !== undefined ? "<br/><pre>Stacktrace (to be sent to the developers): " + err.stack.replace('\n', '<br/>') + "</pre>" : ""
    Vue.swal.fire({
      width: '80%',
      position: 'center',
      icon: 'error',
      title: 'An unexpected error has occured. Click "ok" to resume the application',
      html: `${err.message !== undefined ? "Message: " + err.message: ""}${info !== undefined ? "<br/>Further details: " + info : ""}${stacktrace}`,
      showConfirmButton: true,
      customClass: {
        popup: 'format-pre'
      }
    });
    const message = `${err.message !== undefined ? "Message: " + err.message : ""}${info !== undefined ? ", Further details: " + info : ""}${err.stack !== undefined ? ", Stacktrace: " + err.stack : ""}`
    try {
      await dispatcher.sendClientTelemetry("Bid4It unhandled frontend exception", 500, message);
      //TODO handle socket error with new frontend and old backend
    } catch (e) {
      console.log("Bid4It unhandled frontend exception send error: " + message);
    }
  }

  Vue.config.warnHandler = async (err, vm, info) => {
    const message = `Message: ${err !== undefined ? "Message: " + err : ""}${info !== undefined ? ", Further details: " + info : ""}`
    try {
      await dispatcher.sendClientTelemetry("Bid4It frontend warning", 500, message);
      //TODO handle socket error with new frontend and old backend
    } catch (e) {
      console.log("Bid4It unhandled frontend warning send error: " + message);
    }
  }
}

// Init API base url
httpClient.init(process.env.VUE_APP_SERVER, i18n.locale)

console.log('Running app version ' + process.env.APP_VERSION);

new Vue({
  // eslint-disable-next-line
  // @ts-ignore
  vuetify,
  router,
  i18n,
  pinia,
  async created () {
    // Interceptors Init
    httpClient.interceptors()
  },
  render: (h) => h(App)
}).$mount('#app')
