import SocketClient from '@/utils/socket';
import * as Sentry from '@sentry/vue';
import { SocketNamespace } from '@/utils/constants';

const socketRequests = {
  getUserBidderNumbers: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId
      }
      socket.emit('getUserBidderNumbers', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getUserProfile: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId: 'me'
      }
      socket.emit('getUserProfile', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  updateUserProfile: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId: data.id,
        userData: data
      }
      socket.emit('updateUserProfile', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getAuctions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getAuctions', {}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  requestSignup: async (auctionId: number, userId: number, bidLimit?: number, receiveStartReminderEmail?: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        userId,
        auctionId
      };
      if (bidLimit !== null) {
        payload.signup_data = {
          bidLimit
        };
      }
      if (receiveStartReminderEmail !== undefined) {
        payload.signup_data = {
          ...payload.signup_data,
          notificationAuctionStart: receiveStartReminderEmail
        }
      }
      socket.emit('requestAuctionSignup', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  sendEmailInquiry: async (userId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        userId,
        email_data: data,
      };
      socket.emit('sendInquiryMail', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getWishlist: async (auctionId: number, userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId
      };
      socket.emit('getUserWishlist', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  addToWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId,
        articleId
      };
      socket.emit('addToUserWishlist', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  removeFromWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId,
        articleId
      };
      socket.emit('deleteFromUserWishlist', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getAllCommissionBids: async (auctionId: number, getUserData: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        articleId: 'all'
      };
      if (getUserData) {
        payload.getUserData = true
      }
      socket.emit('getArticleCommissionBids', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  setCommissionBids: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        commission_bids: data.commission_bids
      };
      socket.emit('placeArticleCommissionBids', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getUserKnockdowns: async (auctionId: number, userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId
      };
      socket.emit('getUserKnockdownsPerAuction', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getKnockdownsArticleSlider: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('getUserKnockdownsPerAuctionArticleSlider', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  searchArticles: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getArticlesByQuery', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  validateCommissionBids: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('validateCommissionBids', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  sendMessageOnSocket: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('sendMessage', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getCurrentActiveAuction: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getActiveAuctionState', {}, (response) => { //TODO backend can handle null
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  doLiveBid: async (data): Promise<any> =>
    new Promise((resolve, reject) => {
      const {auctionId, articleId, bid, isUser, isBtr} = data;
      const socketName = isUser ? SocketNamespace.users : SocketNamespace.admins;
      const { socket } = SocketClient.getInstance(socketName);
      const payload: any = {
        auctionId,
        articleId,
        bid,
        isBtr,
        timestamp: Date.now()
      };
      socket.emit('liveBid', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  jumpToArticle: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        articleId
      };
      socket.emit('jumpToArticle', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  undoBid: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('undoBid', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  askBid: async (auctionId: number, value: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        value
      };
      socket.emit('askBid', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  bidWarning: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('bidWarning', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  updateExternalBidderNumber: async (auctionId: number, bidderNumber: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        bidderNumber
      };
      socket.emit('updateExternalBidderNumber', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  sellItem: async (auctionId: number, bidderNumber: number, conditional: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      let payload: any = {
        auctionId,
        conditional
      };
      if (bidderNumber !== null) {
        payload.bidderNumber = bidderNumber;
      }
      socket.emit('sellItem', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  joinLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        browserTabFingerprint
      };
      socket.emit('joinAuction', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  leaveLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        browserTabFingerprint
      };
      socket.emit('leaveAuction', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  getAuctionJoinStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      const payload: any = {
        userId: "me"
      };
      socket.emit('getUserJoinStatus', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  action: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('action', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  getAdminKnockdownsPerAuction: async (auctionId: number, csv: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('getAdminKnockdownsPerAuction', {auctionId, csv}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  getArticleTimedStatus: async (auctionId: number, articleId: number | string): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('getTimedStatus', {auctionId, articleId}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  placeTimedBid: async (auctionId: number, articleId: number, bid: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('timedBid', {auctionId, articleId, maximum_bid: bid}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  getCategories: async (getLastUpdatedTimestamp: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('getCategories', {getLastUpdatedTimestamp}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  getCategoriesGuest: async (getLastUpdatedTimestamp: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('getCategoriesGuest', {getLastUpdatedTimestamp}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  instantBuy: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('instantBuy', {auctionId, articleId}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  updateCategory: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('updateCategory', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  deleteCategory: async (categoryId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('deleteCategory', {categoryId}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  getArticleImages: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('getArticleImages', {auctionId, articleId}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),

  sendClientTelemetry: async (subject: string, returnCode: number, content: string): Promise<void> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();

      socket.emit('sendClientTelemetry', {subject, returnCode, content}, (response) => {
        if (response.data && !response.data.error) {
          resolve();
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    })
}

export default socketRequests;
