<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <v-card class="user-profile-container user-catalogue customizable">
      <div class="close-btn-wrapper" v-if="!modalPosition">
        <v-btn class="close-btn" @click="closeProfileModal()">
          <v-icon class="close-modal-icon">fa-times</v-icon>
        </v-btn>
        <v-btn v-if="isMobile && activeCategory==null" class="filter-icon mt-2" @click="openCategoryModal()">
          <v-icon>fa-filter</v-icon>
        </v-btn>
        <v-btn v-if="isMobile && activeCategory!=null" class="filter-icon mt-2" @click="setCategory(null)">
          <v-icon>fa-rotate-right</v-icon>
        </v-btn>
      </div>
      <v-card-text class="main-card" v-if="auctions">
        <v-container :class="{ 'no-max-width': gridView }">
          <div>
            <v-row align="center" class="form-container justify-center flex-column">
              <v-col sm="12" class="full-width">
                <h2 class="page-title text-center">{{ $t('Search') }}</h2>
              </v-col>
              <div class="scroll-anchor" ref="anchor" />
              <v-progress-circular v-if="searchLoading" class="loading-icon mt-6" indeterminate />
              <template v-else-if="articlesView.length">
                <v-col sm="12" v-if="!error">
                  <div class="catalogue__header">
                    <h3 class="catalogue__sidebar-title" v-if="!categoriesError"> {{ $t('Categories') }}</h3>
                  </div>
                  <div class="catalogue" :class="{ 'list-view': !gridView }">
                    <div class="catalogue__sidebar" v-if="categories && categories.length && !categoriesError">
                      <category-modal
                        ref="categoryModal"
                        :appStoreLang="appStoreLang"
                        @setCategory="setCategory"
                        :setCategory="setCategory"
                        :categories="categories"
                        :activeCategory="activeCategory"
                        :isMobile="isMobile"
                        v-if="isMobile"
                      >
                      </category-modal>
                      <categories-filter
                        :appStoreLang="appStoreLang"
                        @setCategory="setCategory"
                        :categories="categories"
                        :activeCategory="activeCategory"
                        :isMobile="isMobile"
                        v-else
                      >
                      </categories-filter>
                    </div>
                    <div class="catalogue__list" v-if="articlesView.length>0">
                      <div v-for="auction in auctionsData" :key="auction.id" class="search__result">
                        <div v-if="articlesView.filter(el=>el.auctionData.id === auction.id).length">
                          <h4 class="auction-title text-left mb-2">{{ $t('Auction') }} {{ auction.code }}</h4>
                          <div
                            class="catalogue__item" :class="{ highlight: article.is_highlight }"
                            v-for="(article, i) in articlesView.filter(el=>el.auctionData.id === auction.id)" :key="article.id"
                            @click="gridView ? openArticleDetailedModal(article) : false"
                            :style="gridView && itemsPerRow >= 3 ? `width: calc(${100 / itemsPerRow}% - 30px)` : ''"
                          >
                            <div class="catalogue__item-header">
                              <p class="catalogue__item-highlight" v-if="article.is_highlight">
                                {{ $t('Highlight') }}
                              </p>
                              <v-icon
                                class="icon-search-circle icon-with-hover mr-2" small v-if="gridView || isMobile"
                                @click.stop="openArticleDetailedModal(article)"
                              >
                                fa-search
                              </v-icon>
                              <v-tooltip top allow-overflow>
                                <template #activator="{ on, attrs }">
                                  <svg
                                    v-bind="attrs" v-on="on" height="30px" width="30px"
                                    :fill="globalTheme === 'dark' ? '#ffffff' : '#000000'"
                                    xmlns="http://www.w3.org/2000/svg" data-name="Layer 101" viewBox="0 0 100 100" x="0px"
                                    y="0px" class="catalogue__item-shipping icon-with-hover mr-2"
                                    v-if="article.no_shipping || selectedAuctionData.no_shipping"
                                  >
                                    <path
                                      d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z"
                                    />
                                    <path
                                      d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z"
                                    />
                                    <polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" />
                                    <polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" />
                                    <polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" />
                                    <rect x="47.54" y="39.56" width="4.92" height="8.13" />
                                  </svg>
                                </template>
                                <span>{{ $t('No shipping possible') }}</span>
                              </v-tooltip>
                              <v-tooltip top allow-overflow>
                                <template #activator="{ on, attrs }" v-if="enableArticleInqirires">
                                  <div
                                    v-bind="attrs" v-on="on" class="catalogue__item-inquiry icon-with-hover mr-2"
                                    @click.stop="openModal(article)" v-if="isAuthenticatedAsUser"
                                  >
                                    <v-icon>fa-question-circle</v-icon>
                                  </div>
                                </template>
                                <span>{{ $t('Lot inquiry') }}</span>
                              </v-tooltip>
                              <v-tooltip top allow-overflow :key="tableKey">
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs" v-on="on" icon class="catalogue__item-wish icon-with-hover"
                                    :class="{ active: article.wishlist }" @click.stop="addToWishList(article)"
                                    :disabled="wishlistError" v-if="isAuthenticatedAsUser"
                                  >
                                    <v-icon :style="{ fontWeight: article.wishlist ? 900 : 400 }">fa-heart</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('My Wishlist') }}</span>
                              </v-tooltip>
                            </div>
                            <div class="catalogue__item-body">
                              <div class="catalogue__show-image" v-show="article.offensive && !showImages[`article${article.id}`]">
                                <div class="info-text" @click.stop="showImageToggle(article.id)" v-if="isAuthenticatedAsUser">
                                  <v-icon>fa-info-circle</v-icon>
                                  <p class="font-10">
                                    {{ $t('offensive_article_button_show_image') }}<br>
                                    <a :href="appSettings.url_rules" target="_blank">{{ $t('offensive_article_button_show_image2') }}</a>
                                  </p>
                                </div>
                                <v-btn class="btn wrap-text old-styling-btn" @click.stop="openLogin" v-else>{{ $t('Login to see images') }}</v-btn>
                              </div>
                              <div
                                class="catalogue__image" ref="carousel"
                                v-show="showImages[`article${article.id}`] || !article.offensive"
                                v-if="article.images && article.images.length && article.images.length <= 1"
                                @click.stop="e => selectImage(e, article.images, 0,article.auctionData.code)"
                              >
                                <img :src="article.images[0].image">
                              </div>
                              <div
                                class="catalogue__slider" v-else-if="article.images && article.images.length"
                                v-show="showImages[`article${article.id}`] || !article.offensive"
                              >
                                <div class="custom-arrow slick-arrow slick-next" @click.stop="$refs.carousel[i].next()">
                                  <v-icon class="arrow-left">fa-angle-right</v-icon>
                                </div>
                                <div class="custom-arrow slick-arrow slick-prev" @click.stop="$refs.carousel[i].prev()">
                                  <v-icon class="arrow-right">fa-angle-left</v-icon>
                                </div>
                                <VueSlickCarousel
                                  ref="carousel" lazyLoad="ondemand" :arrows="false"
                                  :key="article.images.length" :dots="false"
                                >
                                  <div
                                    class="image-item" v-for="(itm, idx) in article.images" :key="itm.articleNumber"
                                    @click.stop="e => selectImage(e, article.images, idx,article.auctionData.code)"
                                  >
                                    <img
                                      class="article-image" :src="itm.image"
                                      :style="{ 'max-height': isMobile ? '114px' : '156px' }"
                                    >
                                  </div>
                                </VueSlickCarousel>
                              </div>
                              <div class="catalogue__image cursor-pointer" ref="carousel" v-else />
                              <div
                                class="catalogue__item-content"
                                :style="{ width: article.images && article.images.length ? '' : '100%' }"
                              >
                                <h3 class="catalogue__name">{{ articleTitle(article) }}</h3>
                                <v-row justify="space-between" align="center" class="ma-0">
                                  <v-col cols="12" class="pt-0 pb-0 pl-0">
                                    <div class="catalogue__meta">
                                      <div class="catalogue__meta-item">
                                        <p class="catalogue__meta-name">{{ $t('Article') }}</p>
                                        <h5 class="catalogue__meta-value">
                                          #{{ article.number_optional || article.number }}
                                        </h5>
                                      </div>
                                      <div class="catalogue__meta-item" v-if="article.limit && article.auctionData.type !== 'sale'">
                                        <p class="catalogue__meta-name" v-if="article.auctionData.type === 'timed'">
                                          {{ $t('Start price') }}
                                        </p>
                                        <p class="catalogue__meta-name" v-else>{{ $t('Limit') }}</p>
                                        <h5 class="catalogue__meta-value">
                                          {{ currency }} {{ euNumbers(article.limit) }}
                                        </h5>
                                      </div>
                                      <div class="catalogue__meta-item" v-else-if="article.buy_price && article.auctionData.type === 'sale'">
                                        <p class="catalogue__meta-name">{{ $t('Buy price') }}</p>
                                        <h5 class="catalogue__meta-value">
                                          {{ currency }} {{ euNumbers(article.buy_price) }}
                                        </h5>
                                      </div>
                                      <div
                                        class="catalogue__meta-item"
                                        v-if="article.auctionData.type !== 'timed' && article.auctionData.type !== 'sale' && article.estimated_price && (article.auctionData.catalogue_enabled === 'enabled' || article.auctionData.catalogue_enabled === 'enabled_with_results_and_estimations')"
                                      >
                                        <p class="catalogue__meta-name">{{ $t('Estimate') }}</p>
                                        <h5 class="catalogue__meta-value">
                                          {{ currency }} {{ article.estimated_price }}
                                        </h5>
                                      </div>
                                      <div
                                        class="catalogue__meta-item"
                                        v-if="article.auctionData.type === 'timed' && article.timedData"
                                      >
                                        <p class="catalogue__meta-name">{{ $t('Current price') }}</p>
                                        <h5 class="catalogue__meta-value" v-if="article.timedData.current_hammer_price">
                                          {{ currency }} {{ euNumbers(article.timedData.current_hammer_price) }}
                                        </h5>
                                        <h5 class="catalogue__meta-value" v-else>-</h5>
                                      </div>
                                      <!--                                    <div class="catalogue__meta-item" v-if="article.auctionData.type === 'timed' && article.timedData && article.timedData.active_from">-->
                                      <!--                                      <p class="catalogue__meta-name" v-translate>Starts at</p>-->
                                      <!--                                      <h5 class="catalogue__meta-value">{{ getFormattedDate(article.timedData.active_from, appStoreLang, true) }}</h5>-->
                                      <!--                                    </div>-->
                                      <div
                                        class="catalogue__meta-item"
                                        v-if="article.auctionData.type === 'timed' && article.timedData && article.timedData.active_until"
                                      >
                                        <p class="catalogue__meta-name">{{ $t('Ends at') }}</p>
                                        <h5 class="catalogue__meta-value">
                                          {{
                                            getFormattedDate(article.timedData.active_until, appStoreLang, true, false)
                                          }}
                                        </h5>
                                      </div>
                                      <div
                                        class="catalogue__meta-item"
                                        v-if="article.auctionData.type === 'timed' && article.timedData && article.timedData.active_until && getRemainingTime(article.timedData.active_until, redrawRemainingTime)"
                                      >
                                        <p class="catalogue__meta-name">{{ $t('Remaining time') }}</p>
                                        <h5 class="catalogue__meta-value">
                                          {{
                                            getRemainingTime(article.timedData.active_until, redrawRemainingTime)
                                          }}
                                        </h5>
                                      </div>
                                      <div
                                        class="catalogue__meta-item"
                                        v-if="article.auctionData.type === 'live' && article.auctionData.status !== 'preparing' && (article.auctionData.catalogue_enabled === 'enabled_with_results_and_estimations' || article.auctionData.catalogue_enabled === 'enabled_with_results')"
                                      >
                                        <p class="catalogue__meta-name">{{ $t('Knockdown') }}</p>
                                        <h5 class="catalogue__meta-value" v-if="article.sold_price">
                                          {{ currency }} {{
                                            euNumbers(article.sold_price)
                                          }}
                                        </h5>
                                        <h5 class="catalogue__meta-value" v-else>-</h5>
                                      </div>
                                      <v-row class="ma-0 justify-md-end justify-start">
                                        <v-btn
                                          class="btn catalogue__item-shopping mb-3 mb-sm-0 old-styling-btn"
                                          @click.stop="openPostAuctionSaleModal(article)"
                                          v-if="isAuthenticatedAsUser && displayPostAuctionBtn(article.auctionData) && !article.sold_price && !gridView"
                                        >
                                          <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale')
                                          }}</span>
                                        </v-btn>
                                        <v-col
                                          class="pt-0 pl-0 flex-grow-0 text-md-right"
                                          v-if="isAuthenticatedAsUser && (article.auctionData.type === 'sale' || article.auctionData.type === 'timed') && (!gridView || isMobile)"
                                        >
                                          <v-btn
                                            @click.stop="buyArticle(article)" class="btn catalogue__item-buy mt-2 mr-2 old-styling-btn"
                                            v-if="(article.status === '2' || (article.auctionData.status === 'started' && article.auctionData.type === 'sale')) && article.buy_price && article.instant_buy_possible"
                                          >
                                            <v-icon small class="mr-2">fa-shopping-cart</v-icon>
                                            <span>{{ $t('Buy now') }}</span>
                                          </v-btn>
                                          <p
                                            class="mt-2 mb-0 text-no-wrap"
                                            v-if="article.status === '2' && !timedError && article.timedData" readonly
                                          >
                                            <span
                                              class="green--text"
                                              v-if="article.timedData.bidder_status === 'highest-bidder'"
                                            >{{ $t('You are the highest bidder') }}</span>
                                            <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{
                                              $t('You are outbid, bid again')
                                            }}</span>
                                          </p>
                                          <v-btn
                                            class="btn small mt-2 old-styling-btn" @click.stop="openTimedBidModal(article)"
                                            v-if="article.status === '2' && article.auctionData.status === 'started' && !timedError && article.timedData"
                                          >
                                            <span v-if="article.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
                                            <span v-else>{{ $t('Place/Check bid') }}</span>
                                          </v-btn>
                                          <p
                                            class="mt-2 mb-0 text-no-wrap" v-else-if="article.status === '0' && !timedError && article.timedData"
                                            readonly
                                          >
                                            <span class="green--text" v-if="article.timedData.bidder_status === 'bought'">{{
                                              $t('You have bought the article')
                                            }}</span>
                                            <span
                                              class="green--text"
                                              v-if="article.timedData.bidder_status === 'highest-bidder'"
                                            >{{ $t('You have auctioned the article') }}</span>
                                            <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{
                                              $t('Article sold')
                                            }}</span>
                                          </p>
                                        </v-col>
                                        <div
                                          :class="{ 'full-width': gridView }"
                                          v-if="isAuthenticatedAsUser && !gridView && article.auctionData.commission_bids_enabled && article.auctionData.status !== 'completed'"
                                        >
                                          <v-btn
                                            class="btn catalogue__item-shopping mb-3 old-styling-btn" :class="{ small: gridView }"
                                            @click.stop="openBidModal(article)"
                                            v-if="(!article.commission_bids || !article.commission_bids.length) && article.status === '1'"
                                            :disabled="commissionError" key="action-btn"
                                          >
                                            <span>{{ $t('+ Submit absentee bid') }}</span>
                                          </v-btn>
                                          <v-tooltip left v-if="article.commission_bids && article.commission_bids.length">
                                            <template v-slot:activator="{ on, attrs }">
                                              <div v-on="on">
                                                <v-btn class="btn mb-3" :class="article.commission_bids[0].status" disabled key="status-btn">
                                                  <v-icon v-if="article.commission_bids[0].status === 'pending'" style="font-weight: 400;" class="mr-1">fa-hourglass</v-icon>
                                                  <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                                  <v-icon v-if="article.commission_bids[0].status === 'accepted'" class="green-color-success mr-1">fa-check</v-icon>
                                                  <span v-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                                  <v-icon v-if="article.commission_bids[0].status === 'declined'" class="red-color mr-1">fa-times</v-icon>
                                                  <span v-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                                </v-btn>
                                              </div>
                                            </template>
                                            <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid pending') }}</span>
                                            <span v-else-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid accepted') }}</span>
                                            <span v-else-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid declined') }}</span>
                                          </v-tooltip>
                                        </div>
                                      </v-row>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row
                                  class="pb-3 px-3 justify-start justify-md-end"
                                  v-if="article.commission_bids && article.commission_bids.length && !gridView && article.auctionData.status !== 'completed' && article.status === '1'"
                                >
                                  <a class="catalogue__item-change" @click.stop="openBidModal(article, true)">{{
                                    $t('Change bid')
                                  }}</a>
                                </v-row>
                                <p v-if="useHtml" class="catalogue__description" v-html="articleDescription(article)"></p>
                                <p v-else class="catalogue__description">{{ articleDescription(article) }}</p>

                                <template
                                  v-if="isAuthenticatedAsUser && (article.auctionData.type === 'sale' || article.auctionData.type === 'timed') && gridView && !isMobile"
                                >
                                  <v-btn
                                    @click.stop="buyArticle(article)" class="btn catalogue__item-buy mt-2 mr-2 small old-styling-btn"
                                    v-if="(article.status === '2' || (article.auctionData.status === 'started' && article.auctionData.type === 'sale')) && article.buy_price && article.instant_buy_possible"
                                  >
                                    <v-icon small class="mr-2">fa-shopping-cart</v-icon>
                                    <span>{{ $t('Buy now') }}</span>
                                  </v-btn>
                                  <p
                                    class="mt-2 mb-0" v-if="article.status === '2' && !timedError && article.timedData"
                                    readonly
                                  >
                                    <span
                                      class="green--text"
                                      v-if="article.timedData.bidder_status === 'highest-bidder'"
                                    >{{ $t('You are the highest bidder') }}</span>
                                    <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{
                                      $t('You are outbid, bid again')
                                    }}</span>
                                  </p>
                                  <v-btn
                                    class="btn catalogue__item-shopping small mt-2 old-styling-btn"
                                    @click.stop="openTimedBidModal(article)"
                                    v-if="article.status === '2' && article.auctionData.status === 'started' && !timedError && article.timedData"
                                  >
                                    <span v-if="article.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
                                    <span v-else>{{ $t('Place/Check bid') }}</span>
                                  </v-btn>
                                  <p class="mt-2" v-else-if="article.status === '0' && !timedError && article.timedData" readonly>
                                    <span class="green--text" v-if="article.timedData.bidder_status === 'bought'">{{
                                      $t('You have bought the article')
                                    }}</span>
                                    <span
                                      class="green--text"
                                      v-if="article.timedData.bidder_status === 'highest-bidder'"
                                    >{{ $t('You have auctioned the article') }}</span>
                                    <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{
                                      $t('Article sold')
                                    }}</span>
                                  </p>
                                </template>
                                <template
                                  v-if="isAuthenticatedAsUser && gridView && article.auctionData.commission_bids_enabled && article.auctionData.status !== 'completed'"
                                >
                                  <v-btn
                                    class="btn catalogue__item-shopping small mt-2 old-styling-btn"
                                    @click.stop="openBidModal(article)"
                                    v-if="!article.commission_bids.length && article.status === '1'"
                                    :disabled="commissionError"
                                  >
                                    <span>{{ $t('+ Submit absentee bid') }}</span>
                                  </v-btn>
                                  <v-tooltip bottom v-if="article.commission_bids && article.commission_bids.length">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-on="on">
                                        <v-btn class="btn catalogue__item-status small mt-2 old-styling-btn" :class="article.commission_bids[0].status" disabled key="status-btn">
                                          <v-icon v-if="article.commission_bids[0].status === 'pending'" style="font-weight: 400;" class="mr-1">fa-hourglass</v-icon>
                                          <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                          <v-icon v-if="article.commission_bids[0].status === 'accepted'" class="green-color-success mr-1">fa-check</v-icon>
                                          <span v-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                          <v-icon v-if="article.commission_bids[0].status === 'declined'" class="red-color mr-1">fa-times</v-icon>
                                          <span v-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                        </v-btn>
                                      </div>
                                    </template>
                                    <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid pending') }}</span>
                                    <span v-else-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid accepted') }}</span>
                                    <span v-else-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid declined') }}</span>
                                  </v-tooltip>
                                </template>
                                <v-row
                                  class="pb-3 px-3 justify-start mt-md-0 mt-2"
                                  v-if="article.commission_bids && article.commission_bids.length && gridView && article.auctionData.status !== 'completed' && article.status === '1'"
                                >
                                  <a class="catalogue__item-change" @click.stop="openBidModal(article, true)">{{
                                    $t('Change bid')
                                  }}</a>
                                </v-row>
                                <v-btn
                                  class="btn catalogue__item-shopping small old-styling-btn" @click.stop="openPostAuctionSaleModal(article)"
                                  v-if="isAuthenticatedAsUser && displayPostAuctionBtn(article.auctionData) && !article.sold_price && gridView"
                                >
                                  <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale')
                                  }}</span>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col sm="12" class="text-center" v-else>
                  <h3>{{ $t(error) }}</h3>
                </v-col>
              </template>
              <div class="info-text" v-else>
                <v-icon>fa-info-circle</v-icon>
                <p>{{ $t('No articles are found for this search') }}</p>
              </div>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <div class="pagination-container customizable" v-if="auctions && totalItems">
      <v-row class="ma-0" justify="center" align="center">
        <v-col class="pa-0">
          <v-row class="ma-0 d-flex" align="center" :justify="isMobile ? 'space-around' : 'start'">
            <v-menu offset-y close-on-content-click>
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  class="dropdown-btn customizable"
                >
                  <span class="highlight">{{ countPerPageDefault }}<v-icon class="ml-1">fa-angle-down</v-icon></span>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in itemsPerPage"
                  :key="index"
                  @click="changePerPage(item)"
                >
                  <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="ml-2 mr-2 pagination-pages" v-if="!isMobile">
              {{ page * countPerPageDefault + 1 }}-{{ totalPages > 1 ? totalPages === page + 1 ? totalItems : (page + 1) * countPerPageDefault : totalItems }} {{ $t('of') }} {{ totalItems }} {{ $t('items') }}
            </div>
            <div class="wish-list-separator" />
            <v-btn
              @click="openWishList"
              v-if="isAuthenticatedAsUser"
              class="btn old-styling-btn ml-0 wrap-text customizable"
              :disabled="wishlistError"
            >
              <v-icon class="mr-2">fa-heart</v-icon>
              {{ $t('View Wishlist') }}
            </v-btn>
          </v-row>
        </v-col>
        <custom-pagination
          v-if="totalPages > 1"
          :page="page + 1"
          @changePage="changePage"
          :length="totalPages"
          :totalVisible="10"
        />
      </v-row>
    </div>
    <CoolLightBox
      :items="selectedImages"
      :index="lightBoxIndex"
      :useZoomBar="true"
      @close="lightBoxIndex = null"
    />
    <post-auction-sale-modal
      :post-auction-sale-modal="postAuctionSaleModal"
      :article="selectedArticle"
      @closePostAuctionSaleModal="closePostAuctionSaleModal"
    />
    <user-article-inquiry-modal
      ref="inquiryModal"
      :article="selectedArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
    <change-bid-modal
      :commissionBidDialog="bidDialog"
      :editBid="editBid"
      :loading="loading"
      :selectedArticle="selectedArticle"
      :activePage="page"
      :activeCategory="activeCategory"
      :selectedAuctionData="selectedAuctionData"
      update-data
      @getAuctionDetail="getAuctionDetail"
      @setLoading="e => loading = e"
      @closeBidModal="closeBidModal"
    />
    <timed-bid-modal
      :auction="selectedAuctionData"
      :article="selectedArticle"
      @updateArticle="updateArticle"
      ref="timedBidModal"
      :key="modalKey"
    />
    <confirmation-modal
      :title="`${$t('Do you really want to buy the article for')} ${appLocalization.currency} ${euNumbers(selectedArticle.buy_price)}`"
      @submit="submitBuy"
      @cancel="cancelBuy"
      ref="confirmationModal"
    />
    <instant-buy-modal
      ref="instantBuyModal"
      :loading="instantBuyLoading"
      @submitBuyArticle="submitBuyArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
    <article-details-modal
      ref="articleDetailsModal"
      :article="selectedDetailedArticle"
      :commission-error="commissionError"
      :timed-error="timedError"
      :wish-list-error="wishlistError"
      :selected-auction-data="selectedAuctionData"
      :prev-article="prevArticle"
      :next-article="nextArticle"
      @openArticleDetailedModal="openArticleDetailedModal"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import ChangeBidModal from '@/components/modals/user-commission-bid-modal'
import TimedBidModal from '@/components/modals/user-timed-bid-modal'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import InstantBuyModal from '@/components/modals/user-request-instant-buy'
import UserArticleInquiryModal from '@/components/modals/user-article-inquiry-modal'
import articleDetailsModal from '@/components/modals/article-details-modal'
import articleDetails from '@/mixins/article-details'
import counter from '@/mixins/counter'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { AuctionFlavor, localFeatures } from '@/utils/constants'
import CategoriesFilter from '@/components/blocks/categories-filter'
import CategoryModal from '@/components/modals/category-modal'
import PostAuctionSaleModal from '@/components/modals/user-post-auction-sale-modal.vue';
import CustomPagination from '@/components/blocks/custom-pagination.vue'
import dispatcher from '@/api/dispatch';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { euNumbers, getTranslatedText } from '@/services/i18n';
import { getFormattedDate } from '@/services/i18n';

export default {
  name: 'SearchView',
  components: {
    VueSlickCarousel,
    CoolLightBox,
    ChangeBidModal,
    TimedBidModal,
    ConfirmationModal,
    InstantBuyModal,
    UserArticleInquiryModal,
    articleDetailsModal,
    CategoriesFilter,
    CategoryModal,
    CustomPagination,
    PostAuctionSaleModal
  },
  data() {
    return {
      selectedAuctionData: {},
      searchFetchedArticles: [],
      renderComponent: true,
      showProfileModal: false,
      instantBuyLoading: false,
      modalPosition: false,
      profileLocalization: {},
      search: '',
      countPerPageDefault: localFeatures.elementsPerPageDefault,
      itemsPerPage: localFeatures.elementsPerPage,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      tmpArticles: [],
      loading: false,
      gridView: false,
      activeCategory: null,
      articlesView: [],
      auctionsData: [],
      wishListData: [],
      modalKey: 0,
      lightBoxIndex: null,
      selectedImages: [],
      auctions: null,
      getAuctionError: false,
      dialog: false,
      postAuctionSaleModal: false,
      bidDialog: false,
      question: '',
      disableButton: true,
      searchLoading: false,
      selectedArticle: {},
      showUnsold: false,
      bid: null,
      commissionError: false,
      categoriesError: false,
      wishlistError: false,
      editBid: false,
      tableKey: 0,
      timedError: false,
      initArticles: [],
      itemsPerRow: localFeatures.gridViewItemsPerRow,
      showImages: {},
      auctionId: '',
      auctionCode: '',
      validbids: { error: false, msg: '' },
      useHtml: localFeatures.useHtml,
      enableArticleInqirires: localFeatures.enableArticleInqirires
    }
  },
  mixins: [
    articleDetails,
    counter
  ],
  computed: {
    ...mapState(useRootStore, ['globalTheme', 'isMobile', 'userProfile', 'appLocalization', 'appFeatures', 'productCataloguePredefinedValue', 'appSettings', 'appStoreLang', 'categories', 'isAuthenticatedAsUser']),
    totalPages() {
      return Math.ceil(this.totalItems / this.countPerPageDefault)
    },
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    headers() {
      return [
        {
          text: this.$t('Article #'),
          value: 'number',
        },
        {
          text: this.$t('Article name'),
          value: 'name',
        },
        {
          text: this.$t('Description'),
          value: 'description',
        }
      ]
    },
    totalItems() {
      let articles = [...this.tmpArticles]
      if (this.activeCategory) articles = this.tmpArticles.filter(el => el.category_id === this.activeCategory)
      return articles.length
    },
    currency() {
      return this.appLocalization.currency
    },
  },
  async created() {
    await this.getAuctions()
    this.searchData()
  },
  methods: {
    useRootStore,
    ...mapActions(useRootStore, ['updateUserProfile', 'validateCommissionBids', 'fetchCategories', 'SET_TEMP_ALERT']),
    ...mapActions(useAuctionStore, ['getAllCommissionBids']),
    getFormattedDate,
    async addToWishList(article) {
      if (article.wishlist) {
        try {
          await dispatcher.removeFromWishlist(article.auction_id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article has been removed from your wishlist'), timeout: 2000 })
          article.wishlist = false
          this.getArticlesData();
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error removing that article from the wishlist. Please try again later.') })
        }
      } else {
        try {
          await dispatcher.addToWishlist(article.auction_id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article has been placed on your wishlist'), timeout: 2000 })
          article.wishlist = true
          this.getArticlesData();
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error adding that article to the wishlist. Please try again later.') })
        }
      }
      this.tableKey++
    },
    displayPostAuctionBtn(auction) {
      return auction && (auction.catalogue_enabled === 'enabled_with_results' || auction.catalogue_enabled === 'enabled_with_results_and_estimations') && auction.post_auction_sale_enabled && auction.status === 'completed' && auction.type === 'live'
    },
    openCategoryModal() {
      this.$refs.categoryModal.openModal();
    },
    async searchData() {
      this.search = this.$route.query.q;
      if (this.search) {
        this.searchLoading = true
        await this.searchArticles(this.search)
        await this.getAuctionDetail()
        if (this.$route.query.categoryId) this.setCategory(+this.$route.query.categoryId)
        if (this.$route.query.page) {
          if (this.$route.query.page <= this.totalPages) {
            this.changePage(this.$route.query.page);
          } else {
            this.setPage();
          }
        }
      }
    },
    getFieldName(obj, field) {
      let langTarget
      if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
        langTarget = JSON.parse(obj[`${field}_i18n`])
      } else {
        langTarget = obj[`${field}_i18n`]
      }
      if (langTarget && Object.keys(langTarget).length > 0 && langTarget[this.appStoreLang]) {
        return `${field}_i18n`
      }
      return field
    },
    euNumbers,
    changePage (el) {
      this.page = el -1
      this.setPage();
      this.getArticlesData()
      this.$nextTick(() => {
        this.$refs.anchor.scrollIntoView({ behavior: 'smooth' })
      })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    // Close profile modal without saving
    closeProfileModal() {
      this.$router.push({ name: 'dashboard' })
    },

    async getAuctionDetail(auctionID = '', savedPageNumber = 0, savedCategory = null, getCategories = true, getTimedData = true) {
      this.auctionsData = [];
      this.articlesView = []
      this.tmpArticles = []
      this.articles = []
      this.activeCategory = savedCategory
      this.page = savedPageNumber
      let categoriesData = {}
      if (getCategories && this.searchFetchedArticles.length > 0) {
        try {
          categoriesData = await this.fetchCategories();
          this.categoriesError = false
        } catch (e) {
          this.categoriesError = true
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Displaying the categories is currently unavailable due to an technical error. Please try again later.') })
        }
      }

      let auctionMap = this.searchFetchedArticles.map((articlesel) => {
        return new Promise(async(resolve) => {
          await this.articleFilter(articlesel, resolve, categoriesData, getTimedData);
        })
      })
      Promise.all(auctionMap).then((results) => {
        this.auctionsData.sort((a, b) => { return b.id - a.id })
        this.tmpArticles.sort((a, b) => { return a.auction_id - b.auction_id })
        this.getArticlesData()
        this.searchLoading = false
      })
    },
    getArticlesData() {
      let articles = [...this.tmpArticles].filter(el => this.showUnsold ? !el.sold_price : el)
      if (this.activeCategory) {
        articles = this.tmpArticles.filter(el => el.category_id === this.activeCategory)
        this.getAuctionError = !this.tmpArticles.filter(el => el.category_id === this.activeCategory).length
      }
      this.articlesView = articles.slice(this.page * this.countPerPageDefault, (this.page + 1) * this.countPerPageDefault)
    },
    async articleFilter(articlesel, resolveP, categoriesData, getTimedData) {
      let articles = articlesel.results.sort(function(a, b) { return a.id - b.id; });
      let commissionBids = []
      const targetAuction = this.auctions.find(el => el.id === articlesel.id)
      if (this.isAuthenticatedAsUser && targetAuction.commission_bids_enabled && targetAuction.type === AuctionFlavor.live) {
        try {
          const bidsResp = await dispatcher.getAllCommissionBids(articlesel.id, false);
          this.commissionError = false
          commissionBids = bidsResp.commission_bids
        } catch (e) {
          this.commissionError = true
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Absentee bid planning is currently unavailable due to an technical error. Please try again later.') })
        }
      }

      this.auctionsData.push(targetAuction);
      let responseAuction = []
      let articleMap = articles.map(article => {
        return new Promise(async(resolve) => {
          if (categoriesData) {
            for (let category in this.categories) {
              if (!this.categories[category].articlesCount) this.categories[category] = { ...this.categories[category], articlesCount: 0 }
              if (this.categories[category].id === article.category_id) {
                if (targetAuction.type === 'sale') {
                  if (article.status === '1' && article.instant_buy_possible) this.categories[category].articlesCount++
                } else {
                  this.categories[category].articlesCount++
                }
              }
            }
          }
          responseAuction.push({
            ...article,
            commission_bids: commissionBids ? commissionBids.filter(el => el.articles_id === article.id) : [],
            auctionData: targetAuction
          })
          resolve('')
        })
      })
      Promise.all(articleMap).then(async(results) => {
        if (this.searchFetchedArticles.Message) {
          this.error = this.searchFetchedArticles.Message
          this.searchLoading = false
          return
        }
        if (this.isAuthenticatedAsUser) {
          try {
            const wishListResp = await dispatcher.getWishlist(articlesel.id, this.userProfile.id)
            this.wishlistError = false
            this.wishListData = wishListResp

            wishListResp.forEach(el => {
              responseAuction.forEach(article => {
                if (article.id === el.articles_id) {
                  article.wishlist = true
                  article.wishlistId = el.id
                }
              })
            })
          } catch (e) {
            this.wishlistError = true
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('The wishlist is currently unavailable due to a technical error. Please try again later.') })
            throw e;
          }
        }
        if (this.isAuthenticatedAsUser && getTimedData && articlesel.type === 'timed') await this.getTimedData(articlesel.id)
        this.key++
        this.error = null
        this.articles = this.articles.concat(responseAuction);
        this.tmpArticles = this.tmpArticles.concat(responseAuction);
        resolveP('')
      })
    },
    articleTitle(article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription(article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    auctionName(auction) {
      return getTranslatedText(auction, 'name', this.appStoreLang)
    },
    setCategory(id) {
      this.page = 0;
      this.activeCategory = id
      this.getArticlesData()
      if (+this.$route.query.categoryId !== this.activeCategory) this.$router.replace({ query: { ...this.$route.query, categoryId: id } })
    },
    setPage() {
      if (+this.$route.query.page !== (this.page + 1)) this.$router.replace({ query: { ...this.$route.query, page: this.page + 1 } })
    },
    changePerPage(item) {
      this.countPerPageDefault = item
      this.page = 0
      this.setPage();
      this.getArticlesData()
    },
    openWishList() {
      this.$router.push({ name: 'wishlist' })
    },
    getImageOrFallback(path, fallback) {
      return new Promise(resolve => {
        const img = new Image();
        img.src = path;
        img.onload = () => resolve(path);
        img.onerror = () => resolve(fallback);
      });
    },
    async selectImage(e, images, index, code) {
      if (e.target.classList.contains('image-item')) {
        e.target.blur()
      } else {
        e.target.parentNode.blur()
      }
      let arr = []
      if (!this.isMobile) {
        for await (let image of images) {
          const res = await this.getImageOrFallback(this.getImageFullUrl(image.image, code), image.image)
          arr.push(res)
        }
      } else {
        arr = images.map(({ image }) => image)
      }
      this.selectedImages = arr
      this.lightBoxIndex = index
    },

    async searchArticles(term) {
      try {
        const resp = await dispatcher.searchArticles(term, this.appStoreLang)
        this.searchFetchedArticles = resp;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error search the articles. Please try again later') })
        this.error = true;
      }
    },

    /**
     * Get all auctions from api and filter according to conditionals
     * @return {any} - Modified auctions
     */

    async getAuctions() {
      try {
        let data
        if (this.isAuthenticatedAsUser) {
          data = await dispatcher.getAllAuctions();
        } else {
          data = await dispatcher.getGuestAuctions()
        }
        this.auctions = data
        // .filter(el => {
        //   return moment(el.active_until).diff(this.moment(), 'seconds') > 0 && el.catalogue_enabled !== 'disabled'
        // })
      } catch (e) {

        this.error = true;
      }
    },

    async getTimedData(id) {
      try {
        const result = await dispatcher.getArticleTimedStatus(id, "all")
        if (result.timedBids && result.timedBids.length) {
          result.timedBids.forEach(el => {
            this.articles.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
            this.tmpArticles.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
          })
        }
        this.timedError = false
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting timed data. Please try again later') })
        this.timedError = true
      }
    },
    async submitBuyArticle() {
      try {
        this.instantBuyLoading = true
        const data = {
          type: 'instant-buy',
          lang: this.appStoreLang,
          article_id: this.selectedArticle.id
        }
        await dispatcher.sendEmailInquiry(this.userProfile.id, data)
        this.instantBuyLoading = false
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('We have received your request and will get back to you as soon as possible') })
        this.$refs.instantBuyModal.closeModal()
        this.searchLoading = true;
        await this.getAuctionDetail('', this.page)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error sending the inquiry. Please try again later') })
        this.instantBuyLoading = false
      }
    },
    openBidModal(article, editMode = false) {
      this.selectedArticle = article
      this.bidDialog = true
      this.editBid = editMode
    },
    closeBidModal() {
      this.bidDialog = false
      this.selectedArticle = {}
    },
    openTimedBidModal(article) {
      this.selectedArticle = article
      this.selectedAuctionData = article.auctionData
      this.$refs.timedBidModal.openModal()
    },
    openPostAuctionSaleModal(article) {
      this.selectedArticle = article
      this.postAuctionSaleModal = true
    },
    closePostAuctionSaleModal() {
      this.selectedArticle = {}
      this.postAuctionSaleModal = false
    },
    closeShoppingModal() {
      this.$refs.shoppingForm.reset()
      this.shoppingDialog = false
      this.shoppingQuestion = ''
      this.disableButton = true
      this.selectedArticle = {}
      this.postAuctionSaleModal = false
    },
    openModal(article) {
      this.selectedArticle = article
      this.$refs.inquiryModal.open()
    },
    buyArticle(article) {
      this.selectedArticle = article
      if (this.selectedArticle.auctionData.type === 'sale') {
        this.$refs.instantBuyModal.openModal(`${this.currency} ${euNumbers(article.buy_price)}`)
      } else {
        this.$refs.confirmationModal.openModal()
      }
    },
    async submitBuy() {
      try {
        const result = await dispatcher.instantBuy(this.selectedArticle.auction_id, this.selectedArticle.id)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('You have successfully bought the article') })
        let index = this.articles.findIndex((el) => el.id === this.selectedArticle.id);
        this.articles[index] = result;
        let tIndex = this.tmpArticles.findIndex((el) => el.id === this.selectedArticle.id);
        this.tmpArticles[tIndex] = result;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error buying the article. Please try again later') })
      } finally {
        this.$refs.confirmationModal.closeModal()
      }
    },
    cancelBuy() {
      this.$refs.confirmationModal.closeModal()
    },
    updateArticle(data) {
      this.selectedArticle.timedData = data
      let index = this.articles.findIndex((el) => el.id === this.selectedArticle.id);
      this.articles[index] = this.selectedArticle;
      let tIndex = this.tmpArticles.findIndex((el) => el.id === this.selectedArticle.id);
      this.tmpArticles[tIndex] = this.selectedArticle;
      this.getArticlesData()
      this.modalKey++
    },
    getImageFullUrl(url, code) {
      return url.replace(code, `${code}/full`)
    },
    showImageToggle(id) {
      this.showImages = {
        ...this.showImages,
        [`article${id}`]: true
      }
    }
  },
  watch: {
    '$route.query.q'() {
      this.searchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.search__result
{
  width:100%;
}
.slick-prev {
  left: -20px;
  z-index: 2;
  &:before {
    display: none;
  }
}
.slick-next {
  right: -17px;
  z-index: 2;
  &:before {
    display: none;
  }
}
.article-name {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  -webkit-line-clamp: 1;
}
.catalogue {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  .catalogue__item-header {
    margin-bottom: 10px;
    min-height: 30px;
  }
  @media (max-width: 968px) {
    .catalogue__item {
      margin-bottom: 24px;
      padding: 15px;
      width: 100% !important;
      max-width: 100%;
    }
    .catalogue__image,
    .catalogue__slider,
    .catalogue__show-image {
      min-width: 100% !important;
      width: 100% !important;
      height: 114px;
      margin-right: 0 !important;
      border-radius: 4px;
      border: none;
      .slick-slide {
        img {
          max-height: 114px;
        }
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 216px);
    padding-bottom: 60px;
    margin: 0 -15px;
    @media (max-width: 968px) {
      width: 100%;
    }
    .btn {
      @media (max-width: 968px) {
        height: 30px !important;
        padding: 0 8px !important;
        font-size: 10px;
        .v-icon {
          font-size: 16px;
        }
      }
    }
  }
  &.list-view {
    .catalogue__list {
      margin: 0;
    }
    .catalogue__item-header {
      margin-bottom: 0;
    }
    .catalogue__item {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      max-width: 100%;
      padding: 25px;
      &:hover {
        cursor: default;
        border-color: #E5E5E5;
      }
      &-body {
        width: 100%;
        max-width: 100%;
        display: flex;
        @media (max-width: 968px) {
          flex-wrap: wrap;
        }
      }
      &-content {
        width: calc(75% - 25px);
        @media (max-width: 968px) {
          width: 100%;
        }
      }
    }
    .catalogue__item-change {
      font-size: 14px;
      @media (max-width: 968px) {
        font-size: 12px;
      }
    }
    .catalogue__image,
    .catalogue__slider,
    .catalogue__show-image {
      min-width: 25%;
      width: 25%;
      margin-right: 25px;
    }
    .catalogue__description {
      -webkit-line-clamp: initial;
    }
  }
  &__item {
    position: relative;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 25px 15px 55px;
    width: 200px;
    max-width: calc(33.3333% - 30px);
    margin: 0 15px 23px;
    transition: all .3s ease;
    &:hover {
      cursor: pointer;
      border-color: #666;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: -10px -10px 0 0;
    }
    &-inquiry {
      .v-icon {
        font-size: 24px;
      }
    }
  }
  &__item-buy.v-btn {
    background-color: green !important;
    &.small {
      display: block;
      cursor: pointer;
      width: 100%;
      max-width: 240px;
      font-size: 10px;
      padding: 0 6px !important;
      height: 28px !important;
      i {
        font-size: 14px;
      }
    }
  }
  &__item-wish {
    cursor: pointer;
    &:hover {
      i {
        color: #000;
      }
    }
    &.active {
      i {
        color: #8b141e;
        transition: all .3s ease;
      }
    }
  }
  &__item-inquiry,
  &__item-bids {
    cursor: pointer;
    &:hover {
      i {
        color: #000;
      }
    }
    &.active {
      i {
        color: #8b141e;
        transition: all .3s ease;
      }
    }
    .v-icon {
      font-size: 24px;
    }
  }
  &__item-change {
    font-weight: 500;
    margin: 5px 0;
    font-size: 12px;
  }
  &__item-status,
  &__item-shopping {
    margin-top: 10px;
    display: block;
    cursor: pointer;
    width: 100%;
    max-width: 240px;
    &.small {
      font-size: 10px;
      padding: 0 6px !important;
      height: 28px !important;
      i {
        font-size: 14px;
      }
    }
    i {
      font-size: 21px;
    }
    &.active {
      i {
        color: #8b141e;
        transition: all .3s ease;
      }
    }
  }
  &__item-shipping {
    cursor: pointer;
  }
  &__meta {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (max-width: 968px) {
      flex-direction: column;
    }
  }

  &__meta-name {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #7b7b7b;
    @media (max-width: 968px) {
      margin-right: 3px;
    }
  }
  &__meta-value {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
  }
  &__name {
    margin-top: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    -webkit-line-clamp: 3;
    @media (max-width: 968px) {
      -webkit-line-clamp: none;
    }
  }
  &__description {
    margin: 10px 0 0;
    font-size: 12px;
    line-height: 18px;
    color: #484848;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    -webkit-line-clamp: 3;
    @media (max-width: 968px) {
      font-size: 14px;
      color: #262626;
      -webkit-line-clamp: none;
    }
  }
  &__show-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 156px;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 156px;
    img {
      display: block;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  &__slider,
  &__image {
    cursor: zoom-in;
  }
  &__slider {
    position: relative;
    height: 156px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    @media (max-width: 968px) {
      display: none;
    }
  }
  &__header-buttons {
    display: flex;
    align-items: center;
    .v-icon {
      color: #A5A5A5;
      font-size: 20px;
      &:focus {
        &:after {
          display: none;
        }
      }
      &:first-child {
        margin-right: 15px;
      }
      &.active {
        color: #262626;
      }
    }
  }
  &__sidebar {
    width: 192px;
    @media (max-width: 968px) {
      display: none;
    }
  }
  &__sidebar-item {
    position: relative;
    margin: 0 0 12px;
    font-size: 14px;
    line-height: 20px;
    color: #484848;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    padding-right: 32px;
    transition: all .3s ease;
    &.active {
      color: #262626;
      border-color: #262626;
    }
    .v-icon {
      position: absolute;
      bottom: 0;
      right: 4px;
      font-size: 16px;
      line-height: 20px;
      color: #262626;
    }
  }
  &__sidebar-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #000000;
  }
}
.auction-title {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #000000;
}
.auction-date {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.info-text {
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 100%;
  margin: 0 auto 15px;
  background: #87A4CF;
  padding: 11px 24px 11px 19px;
  color: #264D86;
  border-radius: 8px;
  @media screen and (max-width: 968px) {
    padding: 7px 10px;
  }

  .v-icon {
    font-size: 27px;
    color: #264D86;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    margin: 0 0 0 15px;
    @media screen and (max-width: 968px) {
      font-size: 13px;
      line-height: 1.3;
    }
  }
}
.stick-to-bottom {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  bottom: 15px;
  @media screen and (max-width: 968px) {
    position: static;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}
</style>
